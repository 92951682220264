<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout
      row wrap
      :gradient="$vuetify.breakpoint.smAndUp"
      :primary="$vuetify.breakpoint.xsOnly"
      :pt-5="$vuetify.breakpoint.mdAndUp"
      :px-5="$vuetify.breakpoint.mdAndUp"
      :pt-4="$vuetify.breakpoint.smOnly"
      :px-4="$vuetify.breakpoint.smOnly"
    >
      <v-flex xs12 max-width-1400 full-width ma-auto>
        <v-layout row wrap>
          <v-flex xs12 sm6 :px-4="$vuetify.breakpoint.xsOnly" :pt-4="$vuetify.breakpoint.xsOnly">
            <v-card color="transparent" flat tile>

              <v-card-text class="pa-0">
                <h1
                  class="graphik-bold mb-2 mt-0 white--text"
                  :class="{'font-45': $vuetify.breakpoint.smAndUp, 'font-25 text-xs-center': $vuetify.breakpoint.xsOnly}"
                >{{ $ml.get('confirmemail_title') }}</h1>

                <h2
                  class="graphik-light mb-4 white--text"
                  :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17 text-xs-center': $vuetify.breakpoint.xsOnly}"
                >{{ $ml.get('confirmemail_description') }}</h2>

                <v-btn 
                  v-if="$vuetify.breakpoint.smAndUp"
                  color="pantene" 
                  depressed
                  :loading="g_isLoading"
                  class="px-2 normalcase graphik-bold-italic ma-0 border-radius-5 white--text"
                  :disabled="g_isLoading"
                  @click="$goTo('/perfil', 'goto_profile')"
                >{{ $ml.get('general_goto_profile') }}</v-btn>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm6 d-flex align-end :px-4="$vuetify.breakpoint.xsOnly" :pt-4="$vuetify.breakpoint.xsOnly">
            <v-img
              width="100%"
              contain
              :src="image"
              :lazy-src="image"
            />
          </v-flex>

          <v-flex xs12 v-if="$vuetify.breakpoint.xsOnly" secondary :pa-4="$vuetify.breakpoint.xsOnly">
            <v-layout align-center justify-center my-4>
                <v-btn 
                  color="pantene" 
                  depressed
                  :loading="g_isLoading"
                  class="px-2 normalcase graphik-bold-italic ma-0 border-radius-5 white--text"
                  :disabled="g_isLoading"
                  @click="$goTo('/perfil', 'goto_profile')"
                >{{ $ml.get('general_goto_profile') }}</v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'ConfirmEmail',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_CONFIRM_EMAIL,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_CONFIRM_EMAIL
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_CONFIRM_EMAIL
      }
    ]
  },
  data () {
    return {
      image: require('@/assets/img/images/cuco_confirm_email.png'),
    }
  },
  methods: {
    // CRUD
    confirmEmail () {
      this.g_isLoading = true
      this.$api.confirmEmail(
        this.$route.params.token,
        () => {
          this.g_isLoading = false
          this.$analytics('email_confirmed')
        },
        response => this.$errorHandling(response).then(() => {
          this.confirmEmail()
        }, () => {})
      )
    }
  },
  mounted () {
    this.confirmEmail()
  }
}
</script>

<style scoped>
  .gradient {
    background-image: linear-gradient(#4C3AA1, #271B4D) !important;
  }
</style>
